import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { graphql } from 'gatsby';

import './FaqPage.scss';
import PageTitle from 'components/PageTitle';

import FaqAccordion from 'components/FaqAccordion';
import Layout from 'components/Layout';
import Banner from 'components/Banner';
import { FaqPageProps } from './models';

const FaqPage: FC<FaqPageProps> = ({
  data: {
    faq: { faqBanner, faqQuestions, name, seoMetaDescription, seoMetaKeywords, seoMetaTitle },
  },
  pageContext: {
    breadcrumb: { crumbs },
  },
  location: { pathname },
}) => {
  const crumbsSettings = {
    crumbs,
    overWrittenPath: pathname,
    overWrittenLabel: name,
  };

  return (
    <Layout
      seo={{ seoMetaTitle, seoMetaDescription, seoMetaKeywords }}
      crumbsSettings={crumbsSettings}
    >
      <div className="faq-page">
        <Container fluid>
          <Row className="faq-page__row">
            <Col lg={3} sm={12} className="left-menu">
              {faqBanner?.length ? (
                <Banner className="left-menu__banner" {...faqBanner[0].properties} />
              ) : null}
            </Col>
            <Col lg={9} sm={12}>
              {faqQuestions && (
                <>
                  <PageTitle pageTitle={name} />
                  <FaqAccordion items={faqQuestions.map((question) => question.properties)} />
                </>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    faq {
      seoMetaDescription
      seoMetaKeywords
      seoMetaTitle
      url
      name
      faqBanner {
        properties {
          bannerHeadline
          bannerLinkText
          bannerLink {
            url
            name
          }
          bannerImgAria
          bannerImg {
            fallbackUrl
            variants {
              fallbackQuery
            }
          }
        }
      }
      faqQuestions {
        properties {
          answer
          question
        }
      }
    }
  }
`;

export default FaqPage;
